import "../../components/pages/BlogPage.scss";

import { Col, Row } from "react-styled-flexboxgrid";

import BlogRoll from "../../components/sections/BlogRoll/BlogRoll.js";
import BlogTags from "../../components/sections/BlogTags/BlogTags.js";
import Layout from "../../components/layout/Layout";
import Newsletter from "../../components/sections/Newsletter/Newsletter.js";
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import SectionTitle from "../../components/partials/SectionTitle/SectionTitle.js";
import { StyledGrid } from "../../helpers/helpers.js";

export default ({ data }) => {
  return (
    <Layout>
      <StyledGrid className="BlogPage">
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Row>
            <SectionTitle
              text={data.blogContent.frontmatter.title}
              highlight={data.blogContent.frontmatter.highlighted}
            />
            <Col xs={12} md={7} mdOffset={2}>
              <p style={{ marginBottom: "32px" }}>
                {data.blogContent.frontmatter.description}
              </p>
              <BlogTags />
            </Col>
          </Row>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="fadeInBottom"
          duration={1}
          delay={250}
          offset={0}
        >
          <BlogRoll />
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInBottom" duration={1}>
          <Newsletter />
        </ScrollAnimation>
      </StyledGrid>
    </Layout>
  );
};

export const blogPageQuery = graphql`
  query BlogPage {
    blogContent: markdownRemark(frontmatter: { templateKey: { eq: "blog-content" } }) {
      frontmatter {
        title
        highlighted
        description
      }
    }
  }
`;
